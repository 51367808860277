<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="editedItem.label"
                            :label="trans('fields.common.label')"
                            :error-messages="errors.label"
                            @input="clearError('label')"
                        ></v-text-field>
                        <v-text-field
                            v-model="editedItem.locale"
                            :label="trans('fields.common.locale')"
                            :error-messages="errors.locale"
                            @input="clearError('locale')"
                        ></v-text-field>
                        <v-subheader class="ml-0 pl-0">{{ trans('pages.translations') }}</v-subheader>
                        <v-alert
                            v-if="errors.translations"
                            border="bottom"
                            color="red"
                            class="mt-4"
                            dark
                        >
                            {{ errors.translations.join('; ') }}
                        </v-alert>
                        <template v-if="localOptions.locales">
                            <v-text-field
                                v-for="locale in localOptions.locales"
                                :key="'translation-'+locale"
                                v-model="editedItem.translations[locale]"
                                :label="trans('fields.common.locale') + ' ' + locale"
                                :error-messages="errors['translations.'+locale]"
                                @input="clearError('translations'+locale)"
                            ></v-text-field>
                        </template>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="editedItem.id" color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="blue darken-1" text @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "LanguageForm",
        mixins: [formValidate],
        components: {
        },
        props: {
            editedItem: {
                type: Object,
            },
            options: {
                type: [Object, Array],
            }
        },
        data(){
            return {
                localOptions: {},
            }
        },
        created() {
            this.loadOptions()
        },
        methods: {
            loadOptions(){
                axios.get(this.route('admin.options'), {params: {
                        locales: true,
                    }}).then( response => {
                    this.localOptions = response.data;
                })
            },
            save() {
                if (!this.editedItem.id) {
                    axios.post(this.route('admin.languages.store'), this.editedItem).then(() => {
                        console.log('save success');
                        this.$emit('saved');
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                } else {
                    axios.post(this.route('admin.languages.update', this.editedItem.id), this.editedItem).then(() => {
                        console.log('save success');
                        this.$emit('saved');
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.editedItem.id
                    ? this.trans('pages.language.newDialogTitle')
                    : this.trans('pages.language.editDialogTitle');
            },
        }
    }
</script>
